import React, { forwardRef } from "react";

const Console = forwardRef(({ logs, onScroll }, ref) => {
  return (
    <div className="console" style={{ display: 'flex', flexDirection: 'column' }}>
      <div className="console-header" style={{ flexShrink: 0 }}>
        console
      </div>
      <div className="console-content" ref={ref} onScroll={onScroll} style={{ overflowY: 'auto', flexGrow: 1 }}>
        {logs.map((log, index) => (
          <div key={index} className={`log-${log.type}`}>
            {log.message} {log.count ? `(${log.count})` : ""}
          </div>
        ))}
      </div>
    </div>
  );
});

export default Console;
