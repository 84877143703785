import React from 'react';

const Footer = () => {
  const footerStyle = {
    backgroundColor: 'white',
    color: '#36454F',
    textAlign: 'center',
    padding: '10px 0',
    // position: 'fixed',
    width: '100%',
    bottom: 0,
    fontSize: '12px',
    boxShadow: '0 -1px 5px rgba(0, 0, 0, 0.1)',
    zIndex: 1000, // Ensure it stays above other elements
  };

  return (
    <footer style={footerStyle}>
      For support or feedback submission, email <b>help@context-learn.com</b>
    </footer>
  );
};

export default Footer;
