import React from "react";
import "../Assets/Css/styles.min.css";
import logo from "../Assets/images/site-logo.svg";
import { Link } from "react-router-dom";

export default function AuthNav() {
  return (
    <>
      <header className="header-section">
        <div className="header-wrapper header-inner d-flex align-items-center justify-content-between">
          <div className="header-logo logo">
            <Link to="/">
              <img src={logo} alt="Site Logo" />
            </Link>
          </div>
          <div className="right-header header-navigation header-hide">
            <div className="nav-overlay">
              <div className="nav-container">
                <div className="header-nav">
                  <ul>
                    <li>
                      <Link to="#">Home</Link>
                    </li>
                    <li>
                      <Link to="#">Feature</Link>
                    </li>
                    <li>
                      <Link to="#">About</Link>
                    </li>
                    <li>
                      <Link to="#">Pricing</Link>
                    </li>
                  </ul>
                </div>
                <div className="header-btns">
                  <Link to="#" className="button" title="Contact us">
                    Contact us
                  </Link>
                </div>
              </div>
            </div>
            <div className="menu-btn">
              <span className="top"></span>
              <span className="middle"></span>
              <span className="bottom"></span>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
