import React, { useState, useEffect, useContext, useCallback } from "react";
import "../Assets/Css/styles.min.css";
import pageTopBackground from "../Assets/images/page-top-background.png";
import backArrow from "../Assets/images/back-arrow.svg";
import axiosInstance from "../services/axiosInstance";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
import Loading from "./Loading";
import Flash from "./Flash";
import DashNav from "./DashNav";
import Footer from "./Footer"

export default function OnBoarding() {
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isIndex, setIsIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [showFlash, setShowFlash] = useState(false);
  const navigate = useNavigate();
  const [questions] = useState([
    {
      question: "How would you describe your current coding experience?",
      options: [
        {
          type: "select",
          value: "Beginner",
        },
        {
          type: "select",
          value: "Intermediate",
        },
        {
          type: "select",
          value: "Advanced",
        },
      ],
      required: true,
    },
    {
      question:
        "Have you used p5.js or any other creative coding libraries before?",
      options: [
        {
          type: "select",
          value: "Yes",
        },
        {
          type: "select",
          value: "No",
        },
      ],
      required: true,
    },
    {
      question: "What is your main goal in learning creative coding?",
      options: [
        {
          type: "select",
          value: "To complete specific projects",
        },
        {
          type: "select",
          value: "To gain new skills for fun or hobby",
        },
        {
          type: "select",
          value: "To advance my career",
        },
        {
          type: "select",
          value: "To integrate creative coding into my current job",
        },
        {
          type: "text",
          value: "Other",
        },
      ],
      required: true,
    },
    {
      question: "Which types of projects are you interested in creating?",
      options: [
        {
          type: "select",
          value: "Visual art",
        },
        {
          type: "select",
          value: "Interactive websites",
        },
        {
          type: "select",
          value: "Games",
        },
        {
          type: "select",
          value: "Data visualizations",
        },
        {
          type: "text",
          value: "Other",
        },
      ],
      required: true,
    },
    {
      question:
        "Are there any particular concepts you would like to learn in p5.js?",
      options: [
        {
          type: "text",
          value: "Other",
        },
      ],
    },
    {
      question: "How do you prefer to learn new concepts?",
      options: [
        {
          type: "select",
          value: "Reading text",
        },
        {
          type: "select",
          value: "Hands-on exercises",
        },
        {
          type: "select",
          value: "A mix of all",
        },
        {
          type: "text",
          value: "Other",
        },
      ],
    },
    {
      question: "How much time can you dedicate to learning each week?",
      options: [
        {
          type: "select",
          value: "Less than 2 hours",
        },
        {
          type: "select",
          value: "2 to 5 hours",
        },
        {
          type: "select",
          value: "More than 5 hours",
        },
      ],
    },
    {
      question: "How did you hear about Context?",
      options: [
        {
          type: "select",
          value: "Social media",
        },
        {
          type: "select",
          value: "Email",
        },
        {
          type: "select",
          value: "Google Search",
        },
        {
          type: "select",
          value: "Friend/colleague",
        },
        {
          type: "text",
          value: "Other",
        },
      ],
    },
  ]);

  const handleSubmit = useCallback(
    async (finalAnswers) => {
      setMessage("");
      setIsLoading(true);
      try {
        const token = localStorage.getItem("token"); // Assuming token is stored in local storage
        if (!token) {
          setMessage({ error: "User not authenticated." });
          setIsLoading(false);
          setShowFlash(true);
          return;
        }

        const response = await axiosInstance.post("/onboarding", finalAnswers, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          setMessage({ success: response.data.success });
          navigate(`/learning/plan/${response.data.courseId}`);
        }
      } catch (error) {
        setIsLoading(false);
        if (error.response && error.response.data && error.response.data.error) {
          setMessage({ error: error.response.data.error });
        } else {
          setMessage({ error: "An error occurred. Please try again." });
        }
        setShowFlash(true);
      } finally {
        setIsLoading(false);
      }
    },
    [navigate]
  );

  useEffect(() => {
    if (submitted) {
      handleSubmit(answers);
    }
  }, [submitted, answers, handleSubmit]);

  const handleBack = () => {
    if (isIndex > 0) {
      setIsIndex(isIndex - 1);
    }
  };

  const handleChange = (question, option) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [question]: option,
    }));

    if (isIndex !== questions.length - 1) {
      setIsIndex(isIndex + 1);
    } else {
      const requiredQuestions = questions.filter((q) => q.required);
      const allRequiredAnswered = requiredQuestions.every(
        (q) => answers[q.question] && answers[q.question].trim() !== ""
      );

      if (allRequiredAnswered) {
        setSubmitted(true);
      } else {
        setMessage({ error: "Please answer all required questions." });
        setShowFlash(true);
      }
    }
  };

  const handleNext = () => {
    const requiredQuestion = questions[isIndex];
    if (requiredQuestion.required && (!answers[requiredQuestion.question] || answers[requiredQuestion.question].trim() === "")) {
      setMessage({ error: "Please answer all required questions." });
      setShowFlash(true);
    } else {
      setIsIndex(isIndex + 1);
    }
  };

  if (isLoading) {
    return <Loading isLoading={isLoading} loadingText={'Generating Learning Plan...'} />;
  }

  if (!user) return null;

  return (
    <>
      <DashNav fullName={user.fullname} />
      <main id="main-section" className="main-section">
        <section id="hero-section" className="hero-section">
          <div className="page-bg-design shape-into-height">
            <img src={pageTopBackground} alt="Background Design" />
            <div className="overlay"></div>
          </div>
          <div className="hero-home">
            <div className="wrapper">
              <div className="hero-content">
                <h1>Welcome to the Context!</h1>
                <p>
                  Tell us about yourself to generate a learning journey <br />
                  uniquely for you
                </p>
              </div>
            </div>
          </div>
          <div className="questions-ctn">
            <div className="question-box-outer">
              <button
                className={`question-back-btn ${isIndex > 0 ? "current" : ""}`}
                onClick={handleBack}
              >
                <div className="back-arrow">
                  <img src={backArrow} alt="Back Arrow" />
                </div>
                Back
              </button>
              <div className="question-boxes-inner">
                {questions.map((q, index) => (
                  <div
                    key={index}
                    className={
                      index === isIndex
                        ? "question-box-inner current"
                        : "question-box-inner"
                    }
                  >
                    <div className="question-title">
                      {q.question}
                      {q.required && <span className="required">*</span>}
                    </div>
                    <div className="select-choices">
                      {q.options.map(({ type, value }) => (
                        <React.Fragment key={value + index}>
                          {type === "select" ? (
                            <div
                              onClick={() => handleChange(q.question, value)}
                              className="select-question-choice"
                            >
                              {value}
                            </div>
                          ) : (
                            <div
                              onBlur={(e) =>
                                handleChange(q.question, e.target.value)
                              }
                              className="select-question-choice other-choice"
                            >
                              <input type="text" placeholder={value} />
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
              <button
                onClick={handleNext}
                className="question-next-btn current"
              >
                {isIndex !== questions.length - 1 ? "Next" : "Submit"}
                <div className="next-arrow">
                  <img src={backArrow} alt="Next Arrow" />
                </div>
              </button>
            </div>
          </div>
        </section>
        {showFlash && <Flash message={message} />}
      </main>
      <Footer/>
    </>
  );
}
