import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { IoPlaySkipForward, IoPlaySkipBack, IoPlaySharp } from "react-icons/io5";
import { FaPause } from "react-icons/fa6";

let END_POINT;

if (process.env.NODE_ENV === "development") {
  END_POINT = process.env.REACT_APP_END_POINT;
} else {
  END_POINT = process.env.REACT_APP_API_URL_LIVE;
}

export default function Chat({
  chatting = [], // default to an empty array
  userId,
  chatCompleted,
  setChatCompleted,
  setCode,
  startSession,
  setIsQna,
  savedChats,
  setSavedChats, // New prop for saved chats
}) {
  const [currentIndex, setCurrentIndex] = useState(savedChats ? savedChats.length - 1 : -1); // Set initial index based on saved chats
  const [isPlaying, setIsPlaying] = useState(false);
  const [learningStarted, setLearningStarted] = useState(false); // State to manage learning session
  const audioRef = useRef(null);

  const stopCurrentAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current = null;
      setIsPlaying(false);
    }
  };

  const playAudio = async (audioUrl) => {
    const audio = new Audio(audioUrl);
    audioRef.current = audio;
    setIsPlaying(true);
    await new Promise((resolve) => {
      audio.onended = () => {
        setIsPlaying(false);
        resolve();
      };
      audio.play().catch(() => resolve());
    });
  };

  const playAudioAtIndex = async (index) => {
    const chat = chatting[index];
    if (!chat) {
      console.error("Chat not found at index", index);
      return;
    }
    if (chat.code) {
      setCode(chat.code);
    }
    stopCurrentAudio(); // Stop current audio before playing the new one
    if (chat.audioUrl) {
      await playAudio(chat.audioUrl);
    } else {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.post(
          `${END_POINT}/text-to-speech`,
          {
            text: chat.text,
            sessionId: chat._id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const audioUrl = response.data.audioUrl;

        // Store the audio URL in the chat object
        chat.audioUrl = audioUrl;

        await playAudio(audioUrl);
      } catch (error) {
        console.error("Error generating audio:", error);
      }
    }
  };

  const playPrevious = async () => {
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      setCurrentIndex(newIndex);
      await playAudioAtIndex(newIndex);
    }
  };

  const playNext = async () => {
    if (currentIndex < chatting.length - 1) {
      const newIndex = currentIndex + 1;
      setCurrentIndex(newIndex);
      await playAudioAtIndex(newIndex);
      if (newIndex === chatting.length - 1) {
        setChatCompleted(true); // Mark chat as completed when the last text/audio is played
      }
    }
  };

  useEffect(() => {
    const generateAudioInBackground = async () => {
      const pendingChats = chatting.slice(currentIndex + 1);
      const token = localStorage.getItem("token");

      await Promise.all(
        pendingChats.map(async (chat) => {
          if (!chat.audioUrl) {
            try {
              const response = await axios.post(
                `${END_POINT}/text-to-speech`,
                {
                  text: chat.text,
                  sessionId: chat._id,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              chat.audioUrl = response.data.audioUrl;
              // Optionally, you can update the chat object in the database here
            } catch (error) {
              console.error("Error generating audio:", error);
            }
          }
        })
      );
    };

    if (currentIndex < chatting.length - 1) {
      generateAudioInBackground();
    }
  }, [currentIndex, chatting]);

  useEffect(() => {
    if (startSession) {
      setCurrentIndex(0);
    }

    return () => {
      stopCurrentAudio(); // Stop audio when component unmounts
    };
  }, [startSession]);

  // Save the current state of chats when component unmounts
  useEffect(() => {
    return () => {
      setSavedChats(chatting.slice(0, currentIndex + 1));
    };
  }, [currentIndex, chatting, setSavedChats]);

  const startLearning = async () => {
    setLearningStarted(true);
    await playAudioAtIndex(0);
  };

  return (
    <div className="ai-chat-left">
      <div className="chat-area">
        {currentIndex >= 0 && currentIndex < chatting.length && (
          <div
            className={`message ${
              chatting[currentIndex].senderId === userId ? "sent" : "received"
            } transition-message`}
          >
            {chatting[currentIndex].text}
          </div>
        )}
      </div>
      <div className="navigation-buttons">
        {chatCompleted && (
          <div className="button-wrapper">
            <button onClick={() => setIsQna(true)}>Do you have any questions?</button>
          </div>
        )}
        {!learningStarted ? (
          <button onClick={startLearning}>Start Learning</button>
        ) : (
          <div className="play-buttons-wrapper">
            <button onClick={playPrevious} disabled={currentIndex <= 0}>
              <IoPlaySkipBack />
            </button>
            <button onClick={() => (isPlaying ? stopCurrentAudio() : playAudioAtIndex(currentIndex))}>
              {isPlaying ? (
                <FaPause />
              ) : (
                <IoPlaySharp />
              )}
            </button>
            <button
              onClick={playNext}
              disabled={currentIndex >= chatting.length - 1}
            >
              <IoPlaySkipForward />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
