import React from "react";
import { Editor } from "@monaco-editor/react";



const SubmissionCode = ({ code, readOnly }) => {
    return (
        <>
            <Editor
                language="javascript"
                theme="vs-dark"
                value={code}
                options={{ readOnly: readOnly }}
            />
        </>
    );
};

export default SubmissionCode;
