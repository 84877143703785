import React, { useEffect } from "react";
import "../Assets/Css/styles.min.css";
import $ from "jquery";
import logo from "../Assets/images/site-logo.svg";
import crossIcon from "../Assets/images/topbar-cross-icon.svg";

export default function Navbar() {
  useEffect(() => {
    const handleScroll = () => {
      if ($(document).scrollTop() > 0) {
        $("header, body").addClass("shrink");
      } else {
        $("header, body").removeClass("shrink");
      }
    };

    $(document).on("scroll", handleScroll);

    return () => {
      $(document).off("scroll", handleScroll);
    };
  }, []);

  return (
    <header className="header-section">
      <div
        className="top-bar center-align"
        id="top-bar-ajax"
        style={{ display: "none" }}
      >
        <div className="header-wrapper">
          <div className="top-bar-text">
            <a href="#main-section">Learn More</a>
          </div>
        </div>
        <div className="top-bar-cross">
          <span>
            <img src={crossIcon} width="16" height="16" alt="Top bar" />
          </span>
        </div>
      </div>
      <div className="header-wrapper header-inner d-flex align-items-center justify-content-between">
        <div className="header-logo logo">
          <a href="#main-section">
            <img src={logo} alt="Site Logo" />
          </a>
        </div>
        <div className="right-header header-navigation">
          <div className="nav-overlay">
            <div className="nav-container">
              <div className="header-nav">
                <ul>
                  <li>
                    <a href="#main-section">Home</a>
                  </li>
                  <li>
                    <a href="#features">Feature</a>
                  </li>
                  <li>
                    <a href="#about">About</a>
                  </li>
                  <li>
                    <a href="#waitlist">Waitlist</a>
                  </li>
                </ul>
              </div>
              <div className="header-btns">
                <a href="#contact" className="button" title="Contact us">
                  Contact us
                </a>
              </div>
            </div>
          </div>
          {/* <div className="menu-btn">
            <span className="top"></span>
            <span className="middle"></span>
            <span className="bottom"></span>
          </div> */}
        </div>
      </div>
    </header>
  );
}
