import React, { useState, useEffect, useContext } from "react";
import "../Assets/Css/styles.min.css";
import pageTopBackground from "../Assets/images/page-top-background.png";
import axiosInstance from "../services/axiosInstance";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loading from "./Loading";
import DashNav from "./DashNav";
import { AuthContext } from "../Context/AuthContext";
import Footer from "./Footer"

export default function LearningPlan() {
  const { courseId } = useParams();
  const { user } = useContext(AuthContext);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [plans, setPlans] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const generatePlan = async () => {
      try {
        const { status, data } = await axiosInstance.get(
          `/learningPlan/${courseId}`
        );
        if (status === 200) {
          setPlans(data);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    };

    generatePlan();
  }, [courseId, navigate]);

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  if (isLoading) {
    return <Loading isLoading={isLoading} />;
  }

  if (!user || !plans.length) return null;

  return (
    <>
      <DashNav fullName={user.fullname} />
      <main id="main-section" className="main-section">
        <section id="hero-section" className="hero-section">
          <div className="page-bg-design shape-into-height">
            <img src={pageTopBackground} alt="Background Design" />
            <div className="overlay"></div>
          </div>
          <div className="hero-home">
            <div className="wrapper">
              <div className="hero-content">
                <h1>Let's learn!</h1>
              </div>
            </div>
          </div>
        </section>
        <div className="page-section">
          <div className="dropdowns-ctn">
            {plans.length > 0
              ? plans.map((plan, index) => (
                <div
                  key={index}
                  className={`dropdown-box ${activeIndex === index ? "dropdown-active" : ""
                    }`}
                >
                  <div
                    className="dropdown-box-head"
                    onClick={() => handleClick(index)}
                  >
                    <div className="dropdown-box-number">{index + 1}</div>
                    <div className="dropdown-box-head-title">
                      {plan.topicName}
                    </div>
                    <button className="dropdown-head-close">
                      <div className="dropdown-head-close-text">
                        View more
                      </div>
                      <svg
                        className="arrow-rotation"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="32"
                          y="32"
                          width="32"
                          height="32"
                          transform="rotate(-180 32 32)"
                          fill="url(#pattern0_568_1100)"
                        />
                        <defs>
                          <pattern
                            id="pattern0_568_1100"
                            patternContentUnits="objectBoundingBox"
                            width="1"
                            height="1"
                          >
                            <use
                              href="#image0_568_1100"
                              transform="scale(0.0111111)"
                            />
                          </pattern>
                          <image
                            id="image0_568_1100"
                            width="90"
                            height="90"
                            href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAABiklEQVR4nO3aP0sDMRiA8dTBavKhX7gMBVFKRgf9EA5yH8QhuUUQdHEQ/INjpKWL1B4FSS7JPT/InPd9ONqDVikAAAAAAAAAAAAAAAAAQCliXGxPNldP58b6a9OFT23Dq7H+QrlhqVrlhqW2/lJb/2as/zY23Ch51snv3Ua2If46XbhvMrYblpvd9vf1t2kvjnGhbfjYu7jF2O5A5O3xX0riSdL7dRfe/768odhuLHKImwbJZ9BdWB8M3UJsNx55F3pdxCDVxnal7SYPp6bzd6MD2dAreTxTtZBSdyp2sBZ3KX7AlnaoZtAWZq9uYFXnzNUNLhXNWu0CUsGM1S8iBc/WzEJS4EzNLSYFzdLsglLADFlMuajMJfKUC8vcIk+xuMw1cs4As4+cIwSRMwQhcoYwRM4QSOb+xXcs+UcoImeILTzJ6Z9sIXKWn/tNUX8JqJUc9bTyxTdx7J63i/SxiZwhNpEzxO75uEgfuydymle/lenCy+6seIUDAAAAAAAAAAAAAAAAAKj8fgBqod43+S6a7gAAAABJRU5ErkJggg=="
                          />
                        </defs>
                      </svg>
                    </button>
                  </div>
                  <div className="dropdown-box-content">
                    {plan.concepts
                      ? plan.concepts.map(({ subTopic, _id }) => (
                        <div className="dropdown-link">
                          <Link to={`/preview/${plan._id}/${_id}`}>
                            {subTopic}
                          </Link>
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              y="32"
                              width="32"
                              height="32"
                              transform="rotate(-90 0 32)"
                              fill="url(#pattern0_568_1119)"
                            />
                            <defs>
                              <pattern
                                id="pattern0_568_1119"
                                patternContentUnits="objectBoundingBox"
                                width="1"
                                height="1"
                              >
                                <use
                                  href="#image0_568_1119"
                                  transform="scale(0.0111111)"
                                />
                              </pattern>
                              <image
                                id="image0_568_1119"
                                width="90"
                                height="90"
                                href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAABiklEQVR4nO3aP0sDMRiA8dTBavKhX7gMBVFKRgf9EA5yH8QhuUUQdHEQ/INjpKWL1B4FSS7JPT/InPd9ONqDVikAAAAAAAAAAAAAAAAAQCliXGxPNldP58b6a9OFT23Dq7H+QrlhqVrlhqW2/lJb/2as/zY23Ch51snv3Ua2If46XbhvMrYblpvd9vf1t2kvjnGhbfjYu7jF2O5A5O3xX0riSdL7dRfe/768odhuLHKImwbJZ9BdWB8M3UJsNx55F3pdxCDVxnal7SYPp6bzd6MD2dAreTxTtZBSdyp2sBZ3KX7AlnaoZtAWZq9uYFXnzNUNLhXNWu0CUsGM1S8iBc/WzEJS4EzNLSYFzdLsglLADFlMuajMJfKUC8vcIk+xuMw1cs4As4+cIwSRMwQhcoYwRM4QSOb+xXcs+UcoImeILTzJ6Z9sIXKWn/tNUX8JqJUc9bTyxTdx7J63i/SxiZwhNpEzxO75uEgfuydymle/lenCy+6seIUDAAAAAAAAAAAAAAAAAKj8fgBqod43+S6a7gAAAABJRU5ErkJggg=="
                              />
                            </defs>
                          </svg>
                        </div>
                      ))
                      : null}
                    <div className="dropdown-link">
                      <Link to={`/creative-project/${plan._id}/${plan.creativeProject._id}`}>
                        <i className="creative-project-icon">&#9733;</i>
                        Creative Project
                      </Link>
                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <rect y="32" width="32" height="32" transform="rotate(-90 0 32)"
                          fill="url(#pattern0_568_1119)" />
                        <defs>
                          <pattern id="pattern0_568_1119" patternContentUnits="objectBoundingBox" width="1"
                            height="1">
                            <use href="#image0_568_1119" transform="scale(0.0111111)" />
                          </pattern>
                          <image id="image0_568_1119" width="90" height="90"
                            href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAABiklEQVR4nO3aP0sDMRiA8dTBavKhX7gMBVFKRgf9EA5yH8QhuUUQdHEQ/INjpKWL1B4FSS7JPT/InPd9ONqDVikAAAAAAAAAAAAAAAAAQCliXGxPNldP58b6a9OFT23Dq7H+QrlhqVrlhqW2/lJb/2as/zY23Ch51snv3Ua2If46XbhvMrYblpvd9vf1t2kvjnGhbfjYu7jF2O5A5O3xX0riSdL7dRfe/768odhuLHKImwbJZ9BdWB8M3UJsNx55F3pdxCDVxnal7SYPp6bzd6MD2dAreTxTtZBSdyp2sBZ3KX7AlnaoZtAWZq9uYFXnzNUNLhXNWu0CUsGM1S8iBc/WzEJS4EzNLSYFzdLsglLADFlMuajMJfKUC8vcIk+xuMw1cs4As4+cIwSRMwQhcoYwRM4QSOb+xXcs+UcoImeILTzJ6Z9sIXKWn/tNUX8JqJUc9bTyxTdx7J63i/SxiZwhNpEzxO75uEgfuydymle/lenCy+6seIUDAAAAAAAAAAAAAAAAAKj8fgBqod43+S6a7gAAAABJRU5ErkJggg==" />
                        </defs>
                      </svg>
                    </div>
                  </div>
                </div>
              ))
              : null}
          </div>
        </div>
      </main>
      <Footer/>
    </>
  );
}
