import React, { useRef, useState } from "react";
import Homenav from "../Components/Homenav";
import "../Assets/Css/styles.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import background from "../Assets/images/page-top-background.png";
import heroImage from "../Assets/images/uploads/hero-home-image.jpg";
import pageSideDesign from "../Assets/images/page-side-design.svg";
import iatImage from "../Assets/images/uploads/iat-image.jpg";
import leftArrowWhite from "../Assets/images/left-arrow-white.svg";
import rightArrowWhite from "../Assets/images/right-arrow-white.svg";
import midpageCta from "../Assets/images/uploads/midpage-cta.png";
import waveIcon from "../Assets/images/uploads/fluent_hand-wave-20-regular.svg";
import personIcon from "../Assets/images/uploads/material-symbols_person-outline.svg";
import chatIcon from "../Assets/images/uploads/chat-icon.svg";
import creativeIcon from "../Assets/images/uploads/heroicons-outline_light-bulb.svg";
import pencilIcon from "../Assets/images/uploads/mdi_create-outline.svg";
import portfolioIcon from "../Assets/images/uploads/mdi_briefcase-outline.svg";
import empathyIcon from "../Assets/images/uploads/solar_hand-stars-outline.svg";
import iwdIcon from "../Assets/images/uploads/iwd-icon.svg";
import teamMember1 from "../Assets/images/uploads/team-member-01.jpg";
import teamMember2 from "../Assets/images/uploads/team-member-02.jpg";
import ctaGraphic from "../Assets/images/uploads/cta-graphic-02.png";
import Footer from "./Homefoot";
import axios from "axios";
import { Link } from "react-router-dom";
import Flash from "./Flash";
import { FaLinkedin } from "react-icons/fa";

let END_POINT

if (process.env.NODE_ENV === "development") {
    END_POINT = process.env.REACT_APP_END_POINT;
} // TO DO add else once deployed
else {
    END_POINT = process.env.REACT_APP_API_URL_LIVE
}

// For Landing Page

 

export default function LandingPage() {
  const sliderRef = useRef(null);
  const [modal, setModal] = useState(false);
  const [name, SetName] = useState("");
  const [email, setEmail] = useState("");
  const [jti, setJti] = useState("");
  const [interest, setInterest] = useState("");
  const [feedback, setFeedback] = useState("");
  const [hear, setHear] = useState("");
  const [comments, setComments] = useState("");
  const [message, setMessage] = useState("");

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1.5,
    slidesToScroll: 1,
  };

  const handlePrevious = () => {
    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handleOutsideClick = (e) => {
    if (e.target.classList.contains("popup-bg")) {
      setModal(false);
    }
  };

  const handleClick = async () => {
    setModal(true);
    await axios.post(`${END_POINT}/increment/clicks`);
  };

  const handleSubmit = async () => {
    setMessage("");

    if (!name || !email || !interest) {
      setMessage({ error: "Please fill out the required field." });
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setMessage({ error: "Please enter a valid email address." });
      return;
    }

    const formData = {
      name,
      email,
      jti,
      interest,
      feedback,
      hear,
      comments,
    };
    setModal(false);
    await axios.post(`${END_POINT}/waitlist`, formData);
    SetName("");
    setEmail("");
    setJti("");
    setInterest("");
    setFeedback("");
    setHear("");
    setComments("");
  };

    return (
        <>
            {!modal && <Homenav /> }
            <main id="main-section" className="main-section">
                <section id="hero-section" className="hero-section">
                    <div className="page-bg-design">
                        <img src={background} alt="Background Design" />
                        <div className="overlay"></div>
                    </div>
                    <div className="hero-home">
                        <div className="wrapper">
                            <div className="hero-content">
                                <h1>
                                Learn coding from an AI instructor that cares about you
                                </h1>
                                <div className="banner-text">
                                    <h3>
                                    Visual code learning experience tailored to you
                                    </h3>
                                    <button onClick={handleClick} className="button">
                                        Start Learning
                                    </button>
                                </div>
                            </div>
                            <div className="hero-image h-img-cover">
                                <img src={heroImage} alt="context clouds" />
                            </div>
                            <a href="#page-section" className="circle-arrow-button">
                                <svg
                                    width="70"
                                    height="70"
                                    viewBox="0 0 70 70"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M35 61.9792C34.4458 61.9792 33.8917 61.775 33.4542 61.3375L15.75 43.6333C14.9042 42.7875 14.9042 41.3875 15.75 40.5417C16.5958 39.6958 17.9958 39.6958 18.8417 40.5417L35 56.7L51.1583 40.5417C52.0042 39.6958 53.4042 39.6958 54.25 40.5417C55.0958 41.3875 55.0958 42.7875 54.25 43.6333L36.5458 61.3375C36.1083 61.775 35.5542 61.9792 35 61.9792Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M35 61.4833C33.8042 61.4833 32.8125 60.4916 32.8125 59.2958V10.2083C32.8125 9.01248 33.8042 8.02081 35 8.02081C36.1958 8.02081 37.1875 9.01248 37.1875 10.2083V59.2958C37.1875 60.4916 36.1958 61.4833 35 61.4833Z"
                                        fill="white"
                                    />
                                </svg>
                            </a>
                        </div>
                    </div>
                </section>
                <section id="page-section" className="page-section">
                    <div className="s-80"></div>
                    <section>
                        <div className="page-side-design-left">
                            <img src={pageSideDesign} alt="Design element" />
                        </div>
                        <div className="wrapper">
                            <div className="iat-section two-columns justify-content-between align-items-center image-at-right">
                                <div className="iat-image column">
                                    <img src={iatImage} alt="colorful cloud" />
                                </div>
                                <div className="iat-text column">
                                    {/* <div className="kicker">Our Mission</div> */}
                                    <h2>Learn what seems out of reach</h2>
                                    <p>
                                        We use AI to teach coding in the p5.js library and provide you with all the support you need to learn in the way most suitable for you
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="relative-design-element">
                        <div className="page-side-design-right">
                            <img src={pageSideDesign} alt="Design element" />
                        </div>
                    </section>
                    <div id="features" className="s-100"></div>
                    <section className="overflow-hidden">
                        <div className="wrapper">
                            <div className="services-slider">
                                <div className="section-head">
                                    <h2>What to expect?</h2>
                                </div>
                                <div className="services-slider-outer">
                                    <div className="services-slider-buttons">
                                        <div
                                            onClick={handlePrevious}
                                            className="slick-custom-arrow prev-arrow"
                                        >
                                            <img src={leftArrowWhite} alt="Left arrow" />
                                        </div>
                                        <div
                                            onClick={handleNext}
                                            className="slick-custom-arrow next-arrow"
                                        >
                                            <img src={rightArrowWhite} alt="Right arrow" />
                                        </div>
                                    </div>
                                    <div className="services-slider-inner js-services-slider">
                                        <Slider ref={sliderRef} {...settings}>
                                            <div className="service-slide">
                                                <div className="service-icon">
                                                    <img src={waveIcon} alt="Icon" />
                                                </div>
                                                <div className="service-slide-title">
                                                    Getting to know you
                                                </div>
                                                <div className="service-slide-text">
                                                    <p>
                                                        When you join Context, we'll ask about your current experience and goals to tailor the learning experience just for you
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="service-slide">
                                                <div className="service-icon">
                                                    <img src={personIcon} alt="Icon" />
                                                </div>
                                                <div className="service-slide-title">
                                                    Personalized learning plan
                                                </div>
                                                <div className="service-slide-text">
                                                    <p>
                                                        We use AI to craft a learning plan based on your interests, goals, and preferences, ensuring you learn what truly matters to you
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="service-slide">
                                                <div className="service-icon">
                                                    <img src={chatIcon} alt="Icon" />
                                                </div>
                                                <div className="service-slide-title">
                                                    Engaging learning
                                                </div>
                                                <div className="service-slide-text">
                                                    <p>
                                                        Our AI instructor introduces new concepts in the creative coding library p5.js and allows you to ask questions, enabling you to explore topics as deeply as you wish                                                    </p>
                                                </div>
                                            </div>
                                            <div className="service-slide">
                                                <div className="service-icon">
                                                    <img src={pencilIcon} alt="Icon" />
                                                </div>
                                                <div className="service-slide-title">
                                                    Hands-on coding exercises
                                                </div>
                                                <div className="service-slide-text">
                                                    <p>
                                                        Practice coding with Context's support. We'll generate exercises tailored to your pace until you feel confident with the material
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="service-slide">
                                                <div className="service-icon">
                                                    <img src={creativeIcon} alt="Icon" />
                                                </div>
                                                <div className="service-slide-title">
                                                    Work on creative projects
                                                </div>
                                                <div className="service-slide-text">
                                                    <p>
                                                        Express your creativity through project prompts designed around your interests and learning journey
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="service-slide">
                                                <div className="service-icon">
                                                    <img src={portfolioIcon} alt="Icon" />
                                                </div>
                                                <div className="service-slide-title">
                                                    Share your portfolio
                                                </div>
                                                <div className="service-slide-text">
                                                    <p>
                                                        Easily share your creative coding portfolio, whether you're job hunting or showcasing your work to friends
                                                    </p>
                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div id="about" className="s-160"></div>
                    <section>
                        <div className="wrapper">
                            <div className="iwd-ctn">
                                <div className="section-head">
                                    <h2 className="text-36">Learning with Context</h2>
                                </div>

                                <div className="iwd-row">
                                    <div className="iwd-row-column">
                                        <div className="iwd-column-icon">
                                            <img src={personIcon} alt="Icon" />
                                        </div>
                                        <div className="iwd-column-title">
                                            Tailored learning journey
                                        </div>
                                        <div className="iwd-column-text">
                                            <p>
                                                Every aspect of your learning experience, is customized to ensure effective and enjoyable learning                                             </p>
                                        </div>
                                    </div>
                                    <div className="iwd-row-column">
                                        <div className="iwd-column-icon">
                                            <img src={empathyIcon} alt="Icon" />
                                        </div>
                                        <div className="iwd-column-title">
                                            Empathetic Learning
                                        </div>
                                        <div className="iwd-column-text">
                                            <p>
                                                Context never gives up on any learner providing you with the support you need to stay on track
                                            </p>
                                        </div>
                                    </div>
                                    <div className="iwd-row-column">
                                        <div className="iwd-column-icon">
                                            <img src={iwdIcon} alt="Icon" />
                                        </div>
                                        <div className="iwd-column-title">
                                            Unique creative projects
                                        </div>
                                        <div className="iwd-column-text">
                                            <p>
                                                Learn by working on projects that matter to you, tailored to your interests, goals, and preferences
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="relative-design-element">
                        <div className="page-side-design-left">
                            <img src={pageSideDesign} alt="Design element" />
                        </div>
                    </section>
                    <div className="s-160"></div>
                    <section>
                        <div className="wrapper">
                            <div className="mid-page-cta">
                                <div className="mid-page-cta-content">
                                    <h2>Build Your Portfolio</h2>
                                    <p>
                                        Create a professional portfolio that showcases your skills and projects. Share it easily on LinkedIn, GitHub, or your personal website
                                    </p>
                                    <Link onClick={handleClick} className="button">
                                        Try it out
                                    </Link>
                                </div>
                                <div className="mid-page-cta-graphic">
                                    <img src={midpageCta} alt="CTA" />
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="s-100"></div>
                    <section>
                        <div className="wrapper">
                            <div className="leadtext">
                                <h3>
                                    What if every bit of your learning experience was tailored to
                                    your goals, needs, and learning preferences?
                                </h3>
                            </div>
                        </div>
                    </section>
                    <div className="s-100"></div>
                    <section className="overflow-hidden">
                        <div className="wrapper">
                            <div className="team-ctn">
                                <div className="section-head">
                                    <h2>Our Team</h2>
                                    <p>With diverse expertise in generative AI, education, and machine learning, we are excited to create a learning environment that allows each individual to learn in the way most suitable for them</p>
                                </div>
                                <div className="team-members-row">

                                    <div className="team-member-column">
                                        <div className="team-member-image h-img-cover">
                                            <img src={teamMember2} alt="dror profile" />
                                        </div>
                                        <div className="team-member-title">
                                            <h3>Dror Margalit</h3>
                                        </div>

                    <div className="team-member-designation">
                      Founder and CEO
                    </div>

                                        <div className="team-member-socials">
                                            <a href="https://www.linkedin.com/in/dror-margalit/" className="social-icon" target="_blank" rel="noopener noreferrer">
                                                <FaLinkedin className="team-icon" />
                                            </a>                                        </div>
                                    </div>
                                    <div className="team-member-column">
                                        <div className="team-member-image h-img-cover">
                                            <img src={teamMember1} alt="somya profile" />
                                        </div>
                                        <div className="team-member-title">
                                            <h3>Somya Gupta</h3>
                                        </div>

                    <div className="team-member-designation">
                      Founder and CTO
                    </div>

                                        <div className="team-member-socials">
                                            <a href="https://www.linkedin.com/in/somya-gupta-sg/" className="social-icon" target="_blank" rel="noopener noreferrer">
                                                <FaLinkedin className="team-icon" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="s-100"></div>
                    <section>
                        <div id="contact" className="wrapper">
                            <div className="mid-page-cta mid-page-cta-small">
                                <div className="mid-page-cta-content">
                                    <h2>
                                        Start Your Journey with <br /> Context Today!
                                    </h2>
                                    <p>
                                        Join Context’s beta today and learn creative coding with the p5.js. You will get an AI-generated learning experience, personally tailored to your preferences, interests, and goals
                                    </p>
                                    <h4>Contact Us</h4>
                                    <p>
                                        Have questions: emall us at{" "}
                                        <a href="mailto:info@context-learn.com">info@context-learn.com</a>
                                    </p>
                                    <Link onClick={handleClick} className="button">
                                        Test it out
                                    </Link>
                                </div>
                                <div className="mid-page-cta-graphic">
                                    <img src={ctaGraphic} alt="Icon" />
                                </div>
                            </div>
                        </div>
                    </section>
                    <div id="waitlist" className="s-160"></div>
                    <section>
                        <div className="wrapper">
                            <div className="join-meta">
                                <h2>Join our waitlist</h2>
                                <p>
                                    Context's beta will be openning soon.{" "}
                                    <br /> Be among the first to try it
                                </p>
                                <button onClick={handleClick} className="button model-button">
                                    Join Our Beta
                                </button>
                            </div>
                        </div>
                    </section>
                    <div className="s-200"></div>
                    {modal && (
                        <>
                            <div className="popup-area">
                                <div className="popup-bg" onClick={handleOutsideClick}>
                                    <Flash message={message} />
                                    <div className="popup-content">
                                        <div className="popup-content-title">Waitlist</div>
                                        <div className="popup-content-text">
                                            Join Context's beta and try it before anybody else
                                        </div>
                                        <div className="popup-form">
                                            <input
                                                value={name}
                                                onChange={(e) => SetName(e.target.value)}
                                                type="text"
                                                placeholder="Name*"
                                                required
                                            />
                                            <input
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                type="email"
                                                placeholder="Email*"
                                                required
                                            />
                                            <input
                                                value={jti}
                                                onChange={(e) => setJti(e.target.value)}
                                                required
                                                type="text"
                                                placeholder="Job title or industry"
                                            />
                                            <input
                                                value={interest}
                                                onChange={(e) => setInterest(e.target.value)}
                                                required
                                                type="text"
                                                placeholder="Why are you interested in Context?*"
                                            />
                                            <select onChange={(e) => setFeedback(e.target.value)}>
                                                <option selected disabled>
                                                    Are you willing to provide feedback during the beta
                                                    testing phase?
                                                </option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                            <select onChange={(e) => setHear(e.target.value)}>
                                                <option selected disabled>
                                                    How did you hear about us?
                                                </option>
                                                <option value="Option 1">Social media</option>
                                                <option value="Option 2">Friend or co-worker</option>
                                                <option value="Option 3">Email</option>
                                                <option value="Option 4">Google</option>
                                                <option value="Option 5">Other</option>
                                            </select>
                                            <input
                                                value={comments}
                                                onChange={(e) => setComments(e.target.value)}
                                                type="text"
                                                placeholder="Anything else you would want us to know?"
                                            />
                                            <input
                                                onClick={() => handleSubmit()}
                                                type="submit"
                                                value="Join the Beta"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </section>
            </main>
            <Footer />
        </>
    );
}
