import React, { useState, useEffect, useContext } from "react";
import "../Assets/Css/styles.min.css";
import pageTopBackground from "../Assets/images/page-top-background.png";
import axiosInstance from "../services/axiosInstance";
import { useParams } from "react-router-dom";
import DashNav from "./DashNav";
import Loading from "./Loading";
import { AuthContext } from "../Context/AuthContext";
import CodePreview from "./CodePreview";
import SubmissionCode from "./SubmissionCode";

export default function Submission() {
  const { user } = useContext(AuthContext);
  const { creativeId } = useParams();
  const [submission, setSubmission] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmission, setIsSubmission] = useState(true);

  useEffect(() => {
    const getSubmission = async () => {
      try {
        setIsLoading(true);
        const { status, data } = await axiosInstance.get(
          `/submission/${creativeId}`
        );
        if (status === 200) {
          setSubmission(data);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    };

    getSubmission();
  }, [creativeId]);

  if (isLoading) {
    return <Loading isLoading={isLoading} />;
  }

  if (!user || !submission) return null;

  return (
    <>
      <DashNav fullName={user.fullname} />
      <main id="main-section" className="main-section">
        <section id="hero-section" className="hero-section">
          <div className="page-bg-design shape-into-height">
            <img alt="pageTopBackground" src={pageTopBackground} />
            <div className="overlay"></div>
          </div>
          <div className="hero-home">
            <div className="wrapper">
              <div className="ai-chat-tree-row white-model-showing">
                <div className="ai-chat-tree-content">
                  <div className="ai-chat-text-head">
                    <p>{submission.feedback.response}</p>
                  </div>
                  <div className="ai-chat-tree-bottom-button">
                    <button onClick={() => setIsSubmission(false)}>
                      Reveal code
                    </button>
                    <button
                      onClick={() => setIsSubmission(true)}
                      className="current"
                    >
                      Reveal submission
                    </button>
                  </div>
                </div>
                <div className="ai-chat-white-model">
                  {isSubmission ? (
                    <CodePreview code={submission.code} />
                  ) : (
                    <SubmissionCode readOnly={true} code={submission.code} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
