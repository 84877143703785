import React, { useState, useEffect, useContext } from "react";
import "../Assets/Css/styles.min.css";
import axiosInstance from "../services/axiosInstance";
import { Link, useNavigate, useParams } from "react-router-dom";
import DashNav from "./DashNav";
import Loading from "./Loading";
import CodeEditor from "./CodeEditor";
import _ from "lodash";
import QNA from "./QNA";
import { AuthContext } from "../Context/AuthContext";
import Footer from "./Footer"


export default function Practice() {
  const { user } = useContext(AuthContext);
  const { codingId, planId,subtopicId } = useParams();
  const [practice, setPractice] = useState({});
  const [runCode, setRunCode] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [feedback, setFeedBack] = useState({
    response: "Checking your code...",
    lineNumber: null,
  });
  const [code, setCode] = useState("");

  useEffect(() => {
    const getCodingExercise = async () => {
      try {
        const { status, data } = await axiosInstance.get(
          `/exercise/${codingId}`
        );
        if (status === 200) {
          setPractice(data);
          if (data.userCode) {
            setCode(data.userCode);
          } else {
            setCode(data.starterCode);
          }
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    };

    getCodingExercise();
  }, [codingId]);

  useEffect(() => {
    const saveCodeDebounced = _.debounce(async (code, codingId, practiceId) => {
      try {
        await axiosInstance.post(`/save/code/${codingId}/${practiceId}`, {
          code,
        });
      } catch (error) {
        console.error(error);
      }
    }, 5000);

    if (code && practice._id) {
      saveCodeDebounced(code, codingId, practice._id);
    }

    return () => {
      saveCodeDebounced.cancel();
    };
  }, [code, codingId, practice._id]);

  const handleOutsideClick = (e) => {
    if (e.target.classList.contains("popup-bg")) {
      setOpenModal(false);
    }
  };

  const handleCheckMyCode = async () => {
    setOpenModal(true);
    try {
      setFeedBack({
        response: "Checking your code...",
        lineNumber: null,
      });
      const { status, data } = await axiosInstance.get(
        `/evaluate/${codingId}/${practice._id}`
      );
      if (status === 200) {
        setFeedBack(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePracticeMore = async () => {
    try {
      const { status } = await axiosInstance.get(
        `/generate/new/${planId}/${subtopicId}/${codingId}/${practice._id}`
      );

      if (status === 200) {
        navigate(`/learning/plan/${user.courses[0]}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (isLoading) {
    return <Loading isLoading={isLoading}
    loadingText={'Loading Practice...'} />;
  }

  if (!user || !practice) return null;

  return (
    <>
      <DashNav fullName={user.fullname} />
      {/* <main id="main-section" className="main-section"> */}
        {/* <section id="hero-section" className="hero-section"> */}
          {/* <div className="hero-home"> */}
            <div className="ai-chat-ctn">
              <h1 className="ai-chat-section-title">{practice.exercise}</h1>
              <div className="ai-chat-row">
                <QNA
                  userId={user._id}
                  code={code}
                  practiceMode={true}
                  codingId={codingId}
                  exerciseId={practice._id}
                />
                <div className="ai-chat-right">
                  <CodeEditor
                    readOnly={false}
                    code={code}
                    setCode={setCode}
                    runCode={runCode}
                  />
                </div>
              </div>
              <div className="ai-chat-button">
                <button
                  onClick={() => setRunCode(true)}
                  className="no-link-style"
                >
                  <svg
                    width="64"
                    height="64"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="32"
                      cy="32"
                      r="32"
                      fill="url(#paint0_linear_291_819)"
                    />
                    <path
                      d="M47 32L24.5 44.9904L24.5 19.0096L47 32Z"
                      fill="white"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_291_819"
                        x1="32"
                        y1="0"
                        x2="32"
                        y2="64"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#9E5DFF" />
                        <stop offset="1" stopColor="#60D4FF" />
                      </linearGradient>
                    </defs>
                  </svg>
                </button>
                <button
                  onClick={() => setRunCode(false)}
                  className="no-link-style"
                >
                  <svg
                    width="44"
                    height="44"
                    viewBox="0 0 44 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="22"
                      cy="22"
                      r="21.5"
                      stroke="url(#paint0_linear_291_818)"
                    />
                    <rect
                      x="12"
                      y="12"
                      width="20"
                      height="20"
                      fill="url(#paint1_linear_291_818)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_291_818"
                        x1="22"
                        y1="0"
                        x2="22"
                        y2="44"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#9D5FFF" />
                        <stop offset="1" stopColor="#60D5FF" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_291_818"
                        x1="22"
                        y1="12"
                        x2="22"
                        y2="32"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#A059FF" />
                        <stop offset="1" stopColor="#72B2FF" />
                      </linearGradient>
                    </defs>
                  </svg>
                </button>
                <button onClick={handleCheckMyCode}>Check my code</button>
              </div>
            </div>
            {openModal && (
              <div className="chat-popup-ctn">
                <div onClick={handleOutsideClick} className="popup-bg"></div>
                <div className="chat-popup">
                  <div className="chat-popup-text">
                    <p>{feedback.response}</p>
                    {feedback.lineNumber !== null && (
                      <p>at Line number: {feedback.lineNumber}</p>
                    )}
                  </div>
                  <div className="chat-popup-buttons">
                    <button
                      onClick={() => setOpenModal(false)}
                      className="no-link-style revised"
                    >
                      Revised code
                    </button>
                    <button
                      onClick={handlePracticeMore}
                      className="no-link-style outline"
                    >
                      Practice more
                    </button>
                    <button>
                      <Link to={`/learning/plan/${user.courses[0]}`}>
                        Next topic
                      </Link>
                    </button>
                  </div>
                </div>
              </div>
            )}
          {/* </div> */}
        {/* </section> */}
      {/* </main> */}
      <Footer/>
    </>
  );
}