import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@monaco-editor/react";
import Console from "./Console"; // Import the Console component

const CodeEditor = ({ code, readOnly, setCode, runCode }) => {
  const [html] = useState("");
  const [css] = useState("");
  const [srcDoc, setSrcDoc] = useState("");
  const [logs, setLogs] = useState([]); // State to store logs

  const consoleRef = useRef(null); // Ref for the Console component

  const customConsoleLog = `
    (function() {
        const oldLog = console.log;
        console.log = function() {
            window.parent.postMessage({type: 'log', message: Array.from(arguments).join(' ')}, '*');
            oldLog.apply(console, arguments);
        };
    })();
  `;

  const errorHandler = `
    window.onerror = function(message, source, lineno, colno, error) {
        window.parent.postMessage({type: 'error', message: message}, '*');
        return true; // Prevent the firing of the default event handler
    };
  `;

  // To avoid unnecessary re-renders, use a ref to store the previous srcDoc
  const prevSrcDocRef = useRef("");

  useEffect(() => {
    const newSrcDoc = `
      <html>
        <body>${html}</body>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/p5.js/1.4.0/p5.js"></script>
        <style>${css}</style>
        <script>${customConsoleLog}</script>
        <script>${errorHandler}</script>
        <script>${code || "console.log('Code Explanation Coming Soon...');"}</script>
      </html>
    `;
  
    if (newSrcDoc !== prevSrcDocRef.current) {
      setSrcDoc(newSrcDoc);
      prevSrcDocRef.current = newSrcDoc;
      setLogs([]);
    }
  }, [html, css, code, customConsoleLog, errorHandler]);
  
  useEffect(() => {
    const handleMessages = (event) => {
      if (event.data.type === "log" || event.data.type === "error") {
        setLogs((prevLogs) => {
          const lastLog = prevLogs[prevLogs.length - 1];
          if (lastLog && lastLog.message === event.data.message) {
            // Update the count for the last log entry
            const updatedLogs = [...prevLogs];
            updatedLogs[updatedLogs.length - 1] = {
              ...lastLog,
              count: (lastLog.count || 1) + 1,
            };
            return updatedLogs;
          } else {
            return [
              ...prevLogs,
              { type: event.data.type, message: event.data.message },
            ];
          }
        });
      }
    };

    window.addEventListener("message", handleMessages);
    return () => {
      window.removeEventListener("message", handleMessages);
    };
  }, []);

  useEffect(() => {
    if (consoleRef.current) {
      consoleRef.current.scrollTop = consoleRef.current.scrollHeight;
    }
  }, [logs]);

  useEffect(() => {
    setLogs([]);
  }, [runCode]);

  const handleScroll = () => {
    if (consoleRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = consoleRef.current;
      if (scrollHeight - scrollTop === clientHeight) {
        // User has scrolled to the bottom
        consoleRef.current.scrollTop = consoleRef.current.scrollHeight;
      }
    }
  };

  return (
    <>
      <div className="editor-container">
        code editor
        <Editor
          height="50vh"
          width="50vh"
          language="javascript"
          theme="vs-dark"
          value={code || "Code Explanation Coming Soon..."} // Display placeholder text in the editor
          onChange={(e) => setCode(e)}
          options={{ readOnly: readOnly }}
        />
        <Console ref={consoleRef} logs={logs} onScroll={handleScroll} />
      </div>
      <div className="output-container">
        preview
        <iframe
          srcDoc={runCode === true ? srcDoc : ""}
          title="output"
          sandbox="allow-scripts allow-same-origin"
          width="100%"
          height="100%"
        />
      </div>
    </>
  );
};

export default CodeEditor;
