import React from 'react';
import '../Assets/Css/styles.min.css';
import { FaLinkedin, FaInstagram } from 'react-icons/fa';

export default function Footer() {
    return (
        <footer id="footer-section" className="footer-section">
            <div className="wrapper">
                <div className="footer-row">
                    <div className="footer-text">
                        <p className="footer-paragraph">
                            Stay updated with the latest news, tips, and success stories. Follow us on social media:
                            <span className="social-icons">
                                <a href="https://www.instagram.com/context.ai/" className="footer-social" target="_blank" rel="noopener noreferrer">
                                    <FaInstagram className="footer-icon" />
                                </a>
                                <a href="https://www.linkedin.com/company/context-learn-with-ai/about/?viewAsMember=true" className="footer-social" target="_blank" rel="noopener noreferrer">
                                    <FaLinkedin className="footer-icon" />
                                </a>
                            </span>
                        </p>
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>© Copyright 2024, All Rights Reserved</p>
                </div>
            </div>
        </footer>
    );
}
