import React, { useState, useEffect, useContext } from "react";
import "../Assets/Css/styles.min.css";
import axiosInstance from "../services/axiosInstance";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
import DashNav from "./DashNav";
import Footer from "./Footer"
import Loading from "./Loading";
import CodeEditor from "./CodeEditor";
import Chat from "./Chat";
import QNA from "./QNA";

let END_POINT;

if (process.env.NODE_ENV === "development") {
  END_POINT = process.env.REACT_APP_END_POINT;
} else {
  END_POINT = process.env.REACT_APP_API_URL_LIVE;
}

export default function TopicPreview() {
  const { planId, subtopicId } = useParams();
  const { user } = useContext(AuthContext);
  const [teaching, setTeaching] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isQna, setIsQna] = useState(false);
  const [chatCompleted, setChatCompleted] = useState(false);
  const [code, setCode] = useState("");
  const [savedChats, setSavedChats] = useState([]); // New state for saved chats

  const navigate = useNavigate();

  useEffect(() => {
    const generatePlan = async () => {
      try {
        const response = await axiosInstance.get(
          `/teaching/${planId}/${subtopicId}`
        );
        if (response.status === 200) {
          const teachingData = response.data;
          setTeaching(teachingData);

          if (teachingData.codeSession.length > 0) {
            const firstChat = teachingData.codeSession[0];
            if (!firstChat.audioUrl) {
              const token = localStorage.getItem("token");
              const audioResponse = await axiosInstance.post(
                `${END_POINT}/text-to-speech`,
                {
                  text: firstChat.text,
                  sessionId: firstChat._id,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              const audioUrl = audioResponse.data.audioUrl;

              // Update the chat with the generated audio URL in the DB
              await axiosInstance.put(
                `${END_POINT}/update-audio-url/${firstChat._id}`,
                { audioUrl },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  }
                }
              );

              // Update the local chat object as well
              firstChat.audioUrl = audioUrl;
            }
          }
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      }
    };

    generatePlan();
  }, [planId, subtopicId]);

  const createExercise = async () => {
    try {
      setIsLoading(true);
      const { data, status } = await axiosInstance.get(
        `/create/exercise/${planId}/${subtopicId}`
      );
      setIsLoading(false);
      if (status === 200) {
        navigate(`/practice/${planId}/${subtopicId}/${data._id}`);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  if (isLoading) {
    return <Loading isLoading={isLoading} loadingText={'Loading Lesson...'} />;
  }

  if (!user || !teaching) return null;

  return (
    <>
      <DashNav fullName={user.fullname} />
      <div className="ai-chat-ctn">
        <h1 className="ai-chat-section-title">{teaching.subTopic}</h1>

        <div className="practice-qna-header">
          <div className="preview-button-top">
            <button
              className={!isQna ? "active" : ""}
              onClick={() => setIsQna(false)}
            >
              Teaching
            </button>
            <button
              className={isQna ? "active" : ""}
              onClick={() => setIsQna(true)}
            >
              Q&A
            </button>
          </div>

          <div className="ai-chat-button">
            <button onClick={createExercise}>Skip to Practice</button>
          </div>
        </div>

        <div className="ai-chat-row">
          {isQna ? (
            <QNA
              userId={user._id}
              code={code}
              planId={planId}
              subtopicId={subtopicId}
              setCode={setCode}
              practiceMode={false}
            />
          ) : (
            <Chat
              chatCompleted={chatCompleted}
              setChatCompleted={setChatCompleted}
              chatting={teaching.codeSession}
              userId={user._id}
              setCode={setCode}
              startSession={true}
              setIsQna={setIsQna}
              savedChats={savedChats} // Pass saved chats
              setSavedChats={setSavedChats} // Pass setSavedChats function
            />
          )}
          <div className="ai-chat-right">
            <CodeEditor
              runCode={true}
              readOnly={true}
              code={code}
              setCode={setCode}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
