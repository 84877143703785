import React from "react";
import "../Assets/Css/styles.min.css";
import logo from "../Assets/images/site-logo.svg";
import { Link } from "react-router-dom";


export default function DashNav({ fullName }) {

  // const handleClick = () => {
  //   setShowDropdown(!showDropdown);
  // };

  // const handleDashboardClick = () => {
  //   navigate("/dashboard");
  // };

  // const handleSignOutClick = () => {
  //   logout();
  //   navigate("/login");
  // };

  return (
    <>
      <header className="header-section dashboard-header">
     
        <div className="header-wrapper header-inner d-flex align-items-center justify-content-between">
          <div className="header-logo logo">
          <Link to="/">
              <img
                src={logo}
                alt="Site Logo"
                style={{
                  width: "180px", // Adjust width as needed
                  height: "auto" // Maintain aspect ratio
                }}
              />
            </Link>
          </div>
          {/* <div className="right-header header-navigation dasboard-header-right">
            <div className="dashboard-notification">
              <svg
                width="26"
                height="29"
                viewBox="0 0 26 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.86604 26.9293C7.37154 26.4321 6.99947 25.8269 6.77888 25.1613C5.43159 24.9396 4.10445 24.6102 2.80989 24.1763C1.84138 23.8705 1.06477 23.1403 0.700027 22.1923C0.357498 21.2405 0.477127 20.1834 1.024 19.3323L2.40205 17.0323C2.72847 16.4105 2.91682 15.7256 2.95406 15.0243V11.5543C2.95637 8.1897 5.03059 5.17442 8.17146 3.96918C8.7458 2.90808 9.85628 2.24799 11.0628 2.25053C12.2695 2.25306 13.3771 2.91782 13.9471 3.98132C17.071 5.19532 19.1297 8.20185 19.1322 11.5533V15.0273C19.1726 15.7319 19.3605 16.4201 19.6842 17.0473L21.0501 19.3313C21.5649 20.1961 21.6619 21.2469 21.3142 22.1913C20.9713 23.1317 20.2161 23.8629 19.2652 24.1753C17.9755 24.6091 16.6529 24.9382 15.3103 25.1593C14.8251 26.6432 13.6032 27.7681 12.0844 28.1291C10.5655 28.4901 8.96822 28.0353 7.86726 26.9283L7.86604 26.9293ZM9.13704 25.6553C9.67903 26.2006 10.4287 26.4875 11.1962 26.4435C11.9638 26.3995 12.6757 26.0289 13.1521 25.4253C12.4508 25.482 11.7474 25.5103 11.0421 25.5103C10.3367 25.5103 9.63374 25.482 8.93306 25.4253C8.99666 25.5055 9.06477 25.582 9.13704 25.6543V25.6553ZM4.75105 11.5553V15.0293C4.70894 16.0548 4.43538 17.0575 3.951 17.9623L2.57295 20.2623C2.31319 20.6475 2.24678 21.1313 2.39278 21.5723C2.56953 22.0064 2.93684 22.3345 3.38789 22.4613C8.35847 24.1439 13.7445 24.1439 18.715 22.4613C19.1449 22.3198 19.4851 21.9876 19.6369 21.5613C19.7973 21.1324 19.7531 20.6536 19.5168 20.2613L18.1389 17.9613C17.6516 17.0533 17.3779 16.0461 17.3388 15.0163V11.5543C17.3428 8.42092 15.0452 5.76002 11.9447 5.30731C11.3266 5.23048 10.7011 5.23384 10.0838 5.31731C7.01338 5.80034 4.75117 8.44612 4.75105 11.5543V11.5553Z"
                  fill="#809FB8"
                />
                <circle cx="18.5156" cy="7.2493" r="4" fill="#0B83D9" />
                <circle
                  cx="18.5156"
                  cy="7.2493"
                  r="5.5"
                  fill="#0B83D9"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="dashboard-author">
              <div className="author-image">
                {fullName.split(" ").map((name) => name[0])}
                <div className="is-online"></div>
              </div>
              <div className="author-title">{fullName}</div>
              <div
                className={`dropdown-dashboard-author ${
                  showDropdown ? "show" : ""
                }`}
              >
                <button onClick={handleDashboardClick}>Dashboard</button>
                <button onClick={handleSignOutClick}>Sign out</button>
              </div>
            </div>
            <div
              onClick={handleClick}
              className="dashboard-profile-arrows"
              role="button"
            >
              <svg
                width="11"
                height="22"
                viewBox="0 0 11 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.50723 20.1103L9.66226 15.9763C9.96915 15.6709 10.4652 15.6709 10.7721 15.9763C11.0752 16.2803 11.0753 16.7722 10.7722 17.0763L6.06229 21.7623C5.75504 22.0671 5.25943 22.0671 4.9523 21.7623L0.24234 17.0763C-0.0607605 16.7723 -0.0607605 16.2803 0.24234 15.9763C0.549469 15.6715 1.04507 15.6715 1.3522 15.9763L5.50723 20.1103ZM5.50723 1.88928L9.66226 6.02429C9.96915 6.32963 10.4652 6.32963 10.7721 6.02429C11.0757 5.72052 11.0757 5.22812 10.7721 4.92429L6.06229 0.237287C5.75479 -0.0669699 5.25967 -0.0669699 4.9523 0.237287L0.24234 4.92429C-0.0612488 5.22809 -0.0612488 5.72049 0.24234 6.02429C0.549225 6.32963 1.04532 6.32963 1.3522 6.02429L5.50723 1.88928Z"
                  fill="#D9E1E7"
                />
              </svg>  
            </div>
          </div> */}
        </div>
      </header>
    </>
  );
}
