import React, { useEffect, useRef, useState } from "react";

const CodePreview = ({ code }) => {
  const [html] = useState("");
  const [css] = useState("");
  const [srcDoc, setSrcDoc] = useState("");

  const customConsoleLog = `
    (function() {
        const oldLog = console.log;
        console.log = function(message) {
            window.parent.postMessage({type: 'log', message: message}, '*');
            oldLog.apply(console, arguments);
        };
    })();
  `;

  const errorHandler = `
    window.onerror = function(message, source, lineno, colno, error) {
        window.parent.postMessage({type: 'error', message: message}, '*');
        return true; // Prevent the firing of the default event handler
    };
  `;

  // To avoid unnecessary re-renders, use a ref to store the previous srcDoc
  const prevSrcDocRef = useRef("");

  useEffect(() => {
    const newSrcDoc = `
      <html>
        <body>${html}</body>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/p5.js/1.4.0/p5.js"></script>
        <style>${css}</style>
        <script>${customConsoleLog}</script>
        <script>${errorHandler}</script>
        <script>${code}</script>
      </html>
    `;
  
    if (newSrcDoc !== prevSrcDocRef.current) {
      setSrcDoc(newSrcDoc);
      prevSrcDocRef.current = newSrcDoc;
    }
  }, [html, css, code, customConsoleLog, errorHandler]);

  return (
    <>
        <iframe
          srcDoc={srcDoc}
          title="output"
          sandbox="allow-scripts allow-same-origin"
          width="100%"
          height="100%"
        />
    </>
  );
};

export default CodePreview;