import React, { useEffect, useRef, useState, useContext } from "react";
import "../Assets/Css/styles.min.css";
import pageTopBackground from "../Assets/images/page-top-background.png";
import dummyImage from "../Assets/images/uploads/user-avtar.png";
import cardImage from "../Assets/images/uploads/card-image.png";
import axiosInstance from "../services/axiosInstance";
import DashNav from "./DashNav";
import Flash from "./Flash";
import Loading from "./Loading";
import { AuthContext } from "../Context/AuthContext";



// let END_POINT

// if (process.env.NODE_ENV === "development") {
//     END_POINT = process.env.REACT_APP_END_POINT;
// } // TO DO add else once deployed
// else {
//     END_POINT = process.env.REACT_APP_API_URL_LIVE
// }


export default function UserDash() {
  const { user } = useContext(AuthContext);
  const [profile, setProfile] = useState("");
  const [preview, setPreview] = useState("");
  const [isProfile, setIsProfile] = useState(true);
  const [isCourse, setIsCourse] = useState(false);
  const [isSubscription, setIsSubscription] = useState(false);
  const [isPaymentMethod, setIsPaymentMethod] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fullName, setFullName] = useState("");
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState("");

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (user) {
      setProfile(user.profile ? user.profile.path : dummyImage);
      setFullName(user.fullname);
      setUsername(user.username);
      setPhone(user.phone);
    }
  }, [user]);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setProfile(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    setPassword("");

    // Maikng the input to a form data
    const formData = new FormData();
    formData.append("fullname", fullName);
    formData.append("username", username);
    formData.append("phone", phone);
    formData.append("password", password);
    if (profile) {
      formData.append("profile", profile);
    }

    try {
      const response = await axiosInstance.put("/user/edit", formData);

      if (response.status === 200) {
        setMessage(response.data);
      }
    } catch ({ response }) {
      if (response && response.data && response.data.error) {
        setMessage(response.data);
      } else {
        setMessage({
          error: "An unexpected error occurred. Please try again.",
        });
      }
    }
    setIsLoading(false);
  };

  const handleIsProfileClick = () => {
    setIsProfile(true);
    setIsCourse(false);
    setIsSubscription(false);
    setIsPaymentMethod(false);
  };
  const handleIsCourseClick = () => {
    setIsCourse(true);
    setIsProfile(false);
    setIsSubscription(false);
    setIsPaymentMethod(false);
  };
  const handleIsSubscriptionClick = () => {
    setIsSubscription(true);
    setIsProfile(false);
    setIsCourse(false);
    setIsPaymentMethod(false);
  };
  const handleIsPaymentMethodClick = () => {
    setIsPaymentMethod(true);
    setIsProfile(false);
    setIsCourse(false);
    setIsSubscription(false);
  };

  if (isLoading) {
    return <Loading isLoading={isLoading} />;
  }

  if (!user) return null;

  return (
    <>
      <DashNav fullName={fullName} />
      <main id="main-section" className="main-section">
        <section
          id="hero-section"
          className="hero-section hero-section-dashboard"
        >
          <div className="page-bg-design">
            <img src={pageTopBackground} alt="Background Design" />
            <div className="overlay"></div>
          </div>
          <div className="wrapper">
            <div className="hero-dashboard-row">
              <div className="dashboard-sidebar">
                <div className="dashboard-sidebar-inner-content">
                  <button
                    className={`sidebar-link ${isProfile ? "current" : ""}`}
                    onClick={handleIsProfileClick}
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.3425 3.73819L4.72751 8.89981C3.62296 9.76008 2.72705 11.5912 2.72705 12.9799V22.0865C2.72705 24.9377 5.0466 27.2727 7.89387 27.2727H22.1057C24.953 27.2727 27.2725 24.9377 27.2725 22.0988V13.152C27.2725 11.665 26.2784 9.76008 25.0634 8.9121L17.4789 3.59071C15.7607 2.38633 12.9993 2.44778 11.3425 3.73819Z"
                        stroke="#C3C3C3"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15.4546 22.7273V19.0909"
                        stroke="#C3C3C3"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Profile
                  </button>
                  <button
                    className={`sidebar-link ${isCourse ? "current" : ""}`}
                    onClick={handleIsCourseClick}
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.3425 3.73819L4.72751 8.89981C3.62296 9.76008 2.72705 11.5912 2.72705 12.9799V22.0865C2.72705 24.9377 5.0466 27.2727 7.89387 27.2727H22.1057C24.953 27.2727 27.2725 24.9377 27.2725 22.0988V13.152C27.2725 11.665 26.2784 9.76008 25.0634 8.9121L17.4789 3.59071C15.7607 2.38633 12.9993 2.44778 11.3425 3.73819Z"
                        stroke="#C3C3C3"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15.4546 22.7273V19.0909"
                        stroke="#C3C3C3"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Courses
                  </button>
                  <button
                    className={`sidebar-link ${
                      isSubscription ? "current" : ""
                    }`}
                    onClick={handleIsSubscriptionClick}
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.3425 3.73819L4.72751 8.89981C3.62296 9.76008 2.72705 11.5912 2.72705 12.9799V22.0865C2.72705 24.9377 5.0466 27.2727 7.89387 27.2727H22.1057C24.953 27.2727 27.2725 24.9377 27.2725 22.0988V13.152C27.2725 11.665 26.2784 9.76008 25.0634 8.9121L17.4789 3.59071C15.7607 2.38633 12.9993 2.44778 11.3425 3.73819Z"
                        stroke="#C3C3C3"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15.4546 22.7273V19.0909"
                        stroke="#C3C3C3"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Subscription
                  </button>
                  <button
                    className={`sidebar-link ${
                      isPaymentMethod ? "current" : ""
                    }`}
                    onClick={handleIsPaymentMethodClick}
                  >
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.3425 3.73819L4.72751 8.89981C3.62296 9.76008 2.72705 11.5912 2.72705 12.9799V22.0865C2.72705 24.9377 5.0466 27.2727 7.89387 27.2727H22.1057C24.953 27.2727 27.2725 24.9377 27.2725 22.0988V13.152C27.2725 11.665 26.2784 9.76008 25.0634 8.9121L17.4789 3.59071C15.7607 2.38633 12.9993 2.44778 11.3425 3.73819Z"
                        stroke="#C3C3C3"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M15.4546 22.7273V19.0909"
                        stroke="#C3C3C3"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Payment methods
                  </button>
                </div>
              </div>
              <div className="dashboard-content">
                <div
                  className={`tabbed-content profile-tab-part ${
                    isProfile ? "current" : ""
                  }`}
                >
                  <div className="profile-tab-content">
                    <div className="profile-image">
                      <input
                        type="file"
                        accept=".jpg, .png, .jpeg"
                        ref={fileInputRef}
                        onChange={handleImageChange}
                        style={{ display: "none" }}
                      />
                      <img
                        src={preview || profile || dummyImage}
                        alt="user profile"
                      />
                      <button
                        onClick={handleButtonClick}
                        className="upload-profile-image"
                      >
                        <svg
                          width="18"
                          height="15"
                          viewBox="0 0 18 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.25 2.375H13.0844L12.0195 0.778125C11.9625 0.692618 11.8852 0.622502 11.7946 0.573989C11.704 0.525475 11.6028 0.500062 11.5 0.5H6.5C6.39721 0.500062 6.29602 0.525475 6.2054 0.573989C6.11478 0.622502 6.03752 0.692618 5.98047 0.778125L4.91484 2.375H2.75C2.25272 2.375 1.77581 2.57254 1.42417 2.92417C1.07254 3.27581 0.875 3.75272 0.875 4.25V13C0.875 13.4973 1.07254 13.9742 1.42417 14.3258C1.77581 14.6775 2.25272 14.875 2.75 14.875H15.25C15.7473 14.875 16.2242 14.6775 16.5758 14.3258C16.9275 13.9742 17.125 13.4973 17.125 13V4.25C17.125 3.75272 16.9275 3.27581 16.5758 2.92417C16.2242 2.57254 15.7473 2.375 15.25 2.375ZM15.875 13C15.875 13.1658 15.8092 13.3247 15.6919 13.4419C15.5747 13.5592 15.4158 13.625 15.25 13.625H2.75C2.58424 13.625 2.42527 13.5592 2.30806 13.4419C2.19085 13.3247 2.125 13.1658 2.125 13V4.25C2.125 4.08424 2.19085 3.92527 2.30806 3.80806C2.42527 3.69085 2.58424 3.625 2.75 3.625H5.25C5.35292 3.62507 5.45427 3.59971 5.54504 3.5512C5.63581 3.50268 5.71319 3.43249 5.77031 3.34688L6.83437 1.75H11.1648L12.2297 3.34688C12.2868 3.43249 12.3642 3.50268 12.455 3.5512C12.5457 3.59971 12.6471 3.62507 12.75 3.625H15.25C15.4158 3.625 15.5747 3.69085 15.6919 3.80806C15.8092 3.92527 15.875 4.08424 15.875 4.25V13ZM12.125 8.625C12.125 8.79076 12.0592 8.94973 11.9419 9.06694C11.8247 9.18415 11.6658 9.25 11.5 9.25H9.625V11.125C9.625 11.2908 9.55915 11.4497 9.44194 11.5669C9.32473 11.6842 9.16576 11.75 9 11.75C8.83424 11.75 8.67527 11.6842 8.55806 11.5669C8.44085 11.4497 8.375 11.2908 8.375 11.125V9.25H6.5C6.33424 9.25 6.17527 9.18415 6.05806 9.06694C5.94085 8.94973 5.875 8.79076 5.875 8.625C5.875 8.45924 5.94085 8.30027 6.05806 8.18306C6.17527 8.06585 6.33424 8 6.5 8H8.375V6.125C8.375 5.95924 8.44085 5.80027 8.55806 5.68306C8.67527 5.56585 8.83424 5.5 9 5.5C9.16576 5.5 9.32473 5.56585 9.44194 5.68306C9.55915 5.80027 9.625 5.95924 9.625 6.125V8H11.5C11.6658 8 11.8247 8.06585 11.9419 8.18306C12.0592 8.30027 12.125 8.45924 12.125 8.625Z"
                            fill="white"
                          />
                        </svg>
                      </button>
                    </div>
                    <div className="profile-form">
                      <form onSubmit={handleSubmit}>
                        <div className="input-sets">
                          <div className="input-set">
                            <label htmlFor="Username">Username</label>
                            <input
                              type="text"
                              id="Username"
                              placeholder="Username"
                              value={fullName}
                              onChange={(e) => setFullName(e.target.value)}
                            />
                          </div>
                          <div className="input-set">
                            <label htmlFor="Username">Email</label>
                            <input
                              type="email"
                              id="Username"
                              placeholder="abc@gmail.com"
                              value={username}
                              onChange={(e) => setUsername(e.target.value)}
                            />
                          </div>
                          <div className="input-set">
                            <label htmlFor="Username">Phone number</label>
                            <input
                              type="tel"
                              id="Username"
                              placeholder="1234567789"
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                            />
                          </div>
                          <div className="input-set">
                            <label htmlFor="password">Password</label>
                            <input
                              id="password"
                              type={showPassword ? "text" : "password"}
                              placeholder="Password"
                              className="password-type"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <input type="checkbox" />
                            <label
                              onClick={() => setShowPassword(!showPassword)}
                              htmlFor="show-password-checkbox1"
                              className="show-password-checkbox"
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.1083 7.89166L7.8916 12.1083C7.34994 11.5667 7.0166 10.825 7.0166 10C7.0166 8.35 8.34993 7.01666 9.99993 7.01666C10.8249 7.01666 11.5666 7.35 12.1083 7.89166Z"
                                  stroke="#98A2B3"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M14.8499 4.80834C13.3915 3.70834 11.7249 3.10834 9.99987 3.10834C7.0582 3.10834 4.31654 4.84167 2.4082 7.84167C1.6582 9.01667 1.6582 10.9917 2.4082 12.1667C3.06654 13.2 3.8332 14.0917 4.66654 14.8083"
                                  stroke="#98A2B3"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7.0166 16.275C7.9666 16.675 8.97493 16.8917 9.99993 16.8917C12.9416 16.8917 15.6833 15.1583 17.5916 12.1583C18.3416 10.9833 18.3416 9.00834 17.5916 7.83334C17.3166 7.4 17.0166 6.99167 16.7083 6.60834"
                                  stroke="#98A2B3"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M12.9252 10.5833C12.7085 11.7583 11.7502 12.7167 10.5752 12.9333"
                                  stroke="#98A2B3"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M7.8915 12.1083L1.6665 18.3333"
                                  stroke="#98A2B3"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M18.3334 1.66667L12.1084 7.89167"
                                  stroke="#98A2B3"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </label>
                          </div>

                          <div className="input-set">
                            <input type="submit" value="Edit" />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div
                  className={`tabbed-content courses-tab-part ${
                    isCourse ? "current" : ""
                  }`}
                >
                  <div className="courses-details">
                    <div className="courses-overview">
                      <h2 className="text-22">Overview</h2>
                      <div className="courses-overview-row">
                        <div className="single-courses">
                          <div className="courses-overview-text">Progress</div>
                          <div className="courses-overview-number">87</div>
                          <div className="svg-circle">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g opacity="0.3">
                                <path
                                  d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12"
                                  stroke="#292D32"
                                  strokeWidth="1.5"
                                  strokeMiterlimit="10"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M13 11L21.2 2.79999"
                                  stroke="#292D32"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M21.9999 6.83V2H17.1699"
                                  stroke="#292D32"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                            </svg>
                          </div>
                        </div>
                        <div className="single-courses">
                          <div className="courses-overview-text">Completed</div>
                          <div className="courses-overview-number">100</div>
                          <div className="svg-circle">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g opacity="0.3">
                                <path
                                  d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12"
                                  stroke="#292D32"
                                  strokeWidth="1.5"
                                  strokeMiterlimit="10"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M13 11L21.2 2.79999"
                                  stroke="#292D32"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M21.9999 6.83V2H17.1699"
                                  stroke="#292D32"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                            </svg>
                          </div>
                        </div>
                        <div className="single-courses">
                          <div className="courses-overview-text">Community</div>
                          <div className="courses-overview-number">23</div>
                          <div className="svg-circle">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g opacity="0.3">
                                <path
                                  d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12"
                                  stroke="#292D32"
                                  strokeWidth="1.5"
                                  strokeMiterlimit="10"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M13 11L21.2 2.79999"
                                  stroke="#292D32"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M21.9999 6.83V2H17.1699"
                                  stroke="#292D32"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                            </svg>
                          </div>
                        </div>
                        <div className="single-courses">
                          <div className="courses-overview-text">
                            Certificate
                          </div>
                          <div className="courses-overview-number">50</div>
                          <div className="svg-circle">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g opacity="0.3">
                                <path
                                  d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12"
                                  stroke="#292D32"
                                  strokeWidth="1.5"
                                  strokeMiterlimit="10"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M13 11L21.2 2.79999"
                                  stroke="#292D32"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M21.9999 6.83V2H17.1699"
                                  stroke="#292D32"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="s-40"></div>
                      <div className="courses-details">
                        <h3 className="text-18">Course your’re taking</h3>
                        <div className="course-table">
                          <table>
                            <thead>
                              <tr>
                                <td>Course Tittle</td>
                                <td>Lesson Completed</td>
                                <td>Duration</td>
                                <td>Intractor</td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="courses-title">
                                    <div className="inner-flex">
                                      <div className="courses-icon">
                                        <img
                                          src="../assets/src/images/uploads/cource-icon.svg"
                                          alt="Icon"
                                        />
                                      </div>
                                      <div className="courses-inner-title">
                                        UI/UX Designer
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>8/50</td>
                                <td>10h 12m 45s</td>
                                <td>
                                  <div className="courses-title">
                                    <div className="inner-flex">
                                      <div className="courses-icon">
                                        <img
                                          src="../assets/src/images/uploads/cource-icon.svg"
                                          alt="Icon"
                                        />
                                      </div>
                                      <div className="courses-inner-title">
                                        Randy
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="courses-title">
                                    <div className="inner-flex">
                                      <div className="courses-icon">
                                        <img
                                          src="../assets/src/images/uploads/cource-icon.svg"
                                          alt="Icon"
                                        />
                                      </div>
                                      <div className="courses-inner-title">
                                        UI/UX Designer
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>8/50</td>
                                <td>10h 12m 45s</td>
                                <td>
                                  <div className="courses-title">
                                    <div className="inner-flex">
                                      <div className="courses-icon">
                                        <img
                                          src="../assets/src/images/uploads/cource-icon.svg"
                                          alt="Icon"
                                        />
                                      </div>
                                      <div className="courses-inner-title">
                                        Randy
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="courses-title">
                                    <div className="inner-flex">
                                      <div className="courses-icon">
                                        <img
                                          src="../assets/src/images/uploads/cource-icon.svg"
                                          alt="Icon"
                                        />
                                      </div>
                                      <div className="courses-inner-title">
                                        UI/UX Designer
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>8/50</td>
                                <td>10h 12m 45s</td>
                                <td>
                                  <div className="courses-title">
                                    <div className="inner-flex">
                                      <div className="courses-icon">
                                        <img
                                          src="../assets/src/images/uploads/cource-icon.svg"
                                          alt="Icon"
                                        />
                                      </div>
                                      <div className="courses-inner-title">
                                        Randy
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="courses-title">
                                    <div className="inner-flex">
                                      <div className="courses-icon">
                                        <img
                                          src="../assets/src/images/uploads/cource-icon.svg"
                                          alt="Icon"
                                        />
                                      </div>
                                      <div className="courses-inner-title">
                                        UI/UX Designer
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>8/50</td>
                                <td>10h 12m 45s</td>
                                <td>
                                  <div className="courses-title">
                                    <div className="inner-flex">
                                      <div className="courses-icon">
                                        <img
                                          src="../assets/src/images/uploads/cource-icon.svg"
                                          alt="Icon"
                                        />
                                      </div>
                                      <div className="courses-inner-title">
                                        Randy
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="courses-title">
                                    <div className="inner-flex">
                                      <div className="courses-icon">
                                        <img
                                          src="../assets/src/images/uploads/cource-icon.svg"
                                          alt="Icon"
                                        />
                                      </div>
                                      <div className="courses-inner-title">
                                        UI/UX Designer
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td>8/50</td>
                                <td>10h 12m 45s</td>
                                <td>
                                  <div className="courses-title">
                                    <div className="inner-flex">
                                      <div className="courses-icon">
                                        <img
                                          src="../assets/src/images/uploads/cource-icon.svg"
                                          alt="Icon"
                                        />
                                      </div>
                                      <div className="courses-inner-title">
                                        Randy
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`tabbed-content subscription-tab-part ${
                    isSubscription ? "current" : ""
                  }`}
                >
                  <div className="subsciption-content">
                    <div className="subscription-tile">
                      <div className="subscription-title">Popular</div>
                      <div className="subscription-sub-title">
                        What’s included:
                      </div>
                      <ul>
                        <li>Personalized Learning Paths</li>
                        <li>AI Assistance</li>
                        <li>Interactive Coding Exercises</li>
                        <li>Creative Projects</li>
                        <li>Professional Portfolio Builder</li>
                        <li>Certification</li>
                        <li>Gamified Learning</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className={`tabbed-content subscription-tab-part ${
                    isPaymentMethod ? "current" : ""
                  }`}
                >
                  <div className="subsciption-content">
                    <div className="payments-area">
                      <div className="card-image">
                        <img src={cardImage} alt="Card" />
                      </div>
                      <div className="profile-form">
                        <form>
                          <div className="input-sets">
                            <div className="input-set">
                              <label htmlFor="cardnumber">Card number</label>
                              <input
                                type="text"
                                id="cardnumber"
                                placeholder="1111 1111 1111 1111"
                              />
                            </div>
                            <div className="input-set">
                              <label htmlFor="card-holder-name">
                                Name on card
                              </label>
                              <input
                                type="text"
                                id="card-holder-name"
                                placeholder="Tabish Waheed"
                              />
                            </div>
                            <div className="input-set">
                              <label htmlFor="expiry-date">Expiry Date</label>
                              <input
                                type="text"
                                id="expiry-date"
                                placeholder="MM/YYYY"
                              />
                            </div>
                            <div className="input-set">
                              <label htmlFor="cvc">CVC</label>
                              <input type="text" id="cvc" placeholder="123" />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Flash message={message} />
    </>
  );
}
