import React from "react";

export default function Loading({ isLoading, loadingText }) {
  return (
    <>
      {isLoading && (
        <div className="loader-container">
          <div className="loader"></div>
          <div className="loader-text">{loadingText}</div>
        </div>
      )}
    </>
  );
}
