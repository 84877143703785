import React from "react";
import LandingPage from "./Components/LandingPage";
import Login from "./Components/Login";
import Registration from "./Components/Registration";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import UserDash from "./Components/UserDash";
import OnBoarding from "./Components/Onboarding";
import LearningPlan from "./Components/LearningPlan";
import TopicPreview from "./Components/TopicPreview";
import Practice from "./Components/Practice";
import CreativeProject from "./Components/CreativeProject";
import Submission from "./Components/Submission";

function App() {
  
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Registration />} />
          <Route path="/onboarding" element={<OnBoarding />} />
          <Route path="/dashboard" element={<UserDash />} />
          <Route path="/learning/plan/:courseId" element={<LearningPlan />} />
          <Route
            path="/preview/:planId/:subtopicId"
            element={<TopicPreview />}
          />
          <Route
            path="/practice/:planId/:subtopicId/:codingId"
            element={<Practice />}
          />
          <Route
            path="/creative-project/:planId/:creativeId"
            element={<CreativeProject />}
          />
          <Route
            path="/submission/:creativeId"
            element={<Submission />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
