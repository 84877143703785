import React, { useEffect, useState, useRef, useCallback } from "react";
import axiosInstance from "../services/axiosInstance";
import { BsFillSendFill } from "react-icons/bs";

export default function QNA({
  userId,
  code,
  planId,
  subtopicId,
  setCode,
  practiceMode,
  codingId,
  exerciseId,
  creativeId
}) {
  const [input, setInput] = useState("");
  const chatEndRef = useRef(null);
  const [chats, setChats] = useState([
    {
      text: "Do you have any questions?",
    },
  ]);
  const [isTyping, setIsTyping] = useState(false); // State to manage typing indicator
  const [audioQueue, setAudioQueue] = useState([]); // State to manage audio queue
  const [isPlaying, setIsPlaying] = useState(false); // State to manage audio playback status
  const audioRef = useRef(null); // Ref to manage audio playback

  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const fetchQnaChat = async () => {
      try {
        const response = await axiosInstance.get(
          `/get/questions/chat/${subtopicId}`
        );

        if (response.status === 200 && response.data.length) {
          setChats(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchPracticeChats = async () => {
      try {
        const { status, data } = await axiosInstance.get(
          `/practice/chats/${codingId}/${exerciseId}`
        );

        if (status === 200 && data.length) {
          setChats(data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchCreativeProjectChats = async () => {
      try {
        const { status, data } = await axiosInstance.get(
          `/creative-project/chats/${creativeId}`
        );

        if (status === 200 && data.length) {
          setChats(data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (codingId && exerciseId) {
      fetchPracticeChats();
    } else if (subtopicId) {
      fetchQnaChat();
    } else if (creativeId) {
      fetchCreativeProjectChats();
    }
  }, [practiceMode, subtopicId, codingId, exerciseId, creativeId]);

  useEffect(() => {
    scrollToBottom();
  }, [chats]);

  useEffect(() => {
    return () => {
      // Stop audio playback when component unmounts or switches
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, []);

  const playNextAudio = useCallback(async () => {
    if (audioQueue.length > 0) {
      setIsPlaying(true);
      const [nextAudioUrl, ...remainingQueue] = audioQueue;
      const audio = new Audio(nextAudioUrl);
      audioRef.current = audio; // Set audioRef to current audio
      audio.onended = () => {
        setIsPlaying(false);
        setAudioQueue(remainingQueue); // Remove the played audio from the queue
      };
      audio.play().catch((error) => {
        console.error("Error playing audio:", error);
        setIsPlaying(false);
        setAudioQueue(remainingQueue); // Remove the played audio from the queue even if there's an error
      });
    }
  }, [audioQueue]);

  useEffect(() => {
    if (!isPlaying && audioQueue.length > 0) {
      playNextAudio();
    }
  }, [audioQueue, isPlaying, playNextAudio]);

  const handleStatusChange = async () => {
    try {
      await axiosInstance.get(`/chat/status/${subtopicId}`);
    } catch (error) {
      console.error(error);
    }
  };

  const delayMessageDisplay = async (data) => {
    setIsTyping(false); // Hide typing indicator when response is received
    for (let i = 0; i < data.length; i++) {
      const chat = data[i];
    
      if (chat.newChat) {
        if (chat.code !== "###noCode###") {
          setCode(chat.code);
        }
        setChats((prevChats) => {
          if (!prevChats.find((c) => c.text === chat.text)) {
            return [...prevChats, chat];
          }
          return prevChats;
        });
        
        if (chat.audioUrl) {
          setAudioQueue((prevQueue) => [...prevQueue, chat.audioUrl]);
        } else {
          await new Promise((resolve) => setTimeout(resolve, 3000));
        }
      }
      handleStatusChange();
    }
  };

  const practiceMessageHandler = async (data) => {
    for (let i = 0; i < data.length; i++) {
      const chat = data[i];
      setChats((prevChats) => {
        if (!prevChats.find((c) => c.text === chat.text)) {
          return [...prevChats, chat];
        }
        return prevChats;
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      input,
      senderId: userId,
      code,
    };

    const newMessage = { text: input, senderId: userId };
    setChats((prevChats) => [...prevChats, newMessage]);
    setInput("");
    setIsTyping(true); // Show typing indicator when message is submitted
    try {
      if (planId && subtopicId) {
        const { data } = await axiosInstance.post(
          `/questions/${planId}/${subtopicId}`,
          formData
        );
        delayMessageDisplay(data);
      } else if (codingId && exerciseId) {
        const { data, status } = await axiosInstance.post(
          `/practice/question/${codingId}/${exerciseId}`,
          formData
        );
        if (status === 200) {
          practiceMessageHandler(data);
        }
      } else if (creativeId) {
        const { data, status } = await axiosInstance.post(
          `/creative-project/question/${creativeId}`,
          formData
        );

        if (status === 200) {
          practiceMessageHandler(data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="ai-chat-left">
        <div className="qna-chat-area">
          {chats.map((message, index) => (
            <div
              key={index}
              className={`qna-message ${message.senderId ? "sent" : "received"}`}
            >
              {message.text}
            </div>
          ))}
          {isTyping && <div className="typing-indicator">Teacher is typing...</div>}
          <div ref={chatEndRef} />
        </div>
        <div className="chat-type">
          <form onSubmit={handleSubmit}>
            <div className="input-container">
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Type your message..."
              />
              <button type="submit">
                <BsFillSendFill />
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
